// extracted by mini-css-extract-plugin
export var body404 = "contentGrid-module--body404--755ed";
export var categoryH2 = "contentGrid-module--categoryH2--0cd4f";
export var contentGrid = "contentGrid-module--contentGrid--f357a";
export var contentGridHome = "contentGrid-module--contentGridHome--ca3e2";
export var contentIcon = "contentGrid-module--contentIcon--ad879";
export var iconBook = "contentGrid-module--iconBook--c9744";
export var iconEvent = "contentGrid-module--iconEvent--92054";
export var iconExhibition = "contentGrid-module--iconExhibition--00854";
export var iconLink = "contentGrid-module--iconLink--701ce";
export var iconMagazine = "contentGrid-module--iconMagazine--44f6c";
export var iconNews = "contentGrid-module--iconNews--30288";
export var iconTalk = "contentGrid-module--iconTalk--94f49";
export var itemTitle = "contentGrid-module--itemTitle--3f520";
export var itemTitleBook = "contentGrid-module--itemTitleBook--37f22";
export var itemTitleEvent = "contentGrid-module--itemTitleEvent--7e523";
export var itemTitleExhibition = "contentGrid-module--itemTitleExhibition--f0398";
export var itemTitleMagazine = "contentGrid-module--itemTitleMagazine--12ba6";
export var itemTitleNews = "contentGrid-module--itemTitleNews--1551e";
export var itemTitleTalk = "contentGrid-module--itemTitleTalk--0671d";
export var main = "contentGrid-module--main--d65fd";