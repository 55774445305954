import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../scss/main.scss';
import * as styles from '../scss/components/contentGrid.module.scss';
import Header from '../components/Header';
import MenuModal from '../components/MenuModal';
import Footer from '../components/Footer';

export default function CategoryPage({ pageContext, data }:any) {
  const {
    title,
  } = pageContext;

  const [menuOpen, setMenuOpen] = useState(false);

  function convertTitleToHeader() {
    switch (title) {
      case 'exhibition':
        return 'Exhibitions';
      case 'talk':
        return 'Talks';
      case 'event':
        return 'Events';
      case 'magazine':
        return 'Magazine';
      case 'book':
        return 'Books';
      case 'news':
        return 'News';
      default:
        throw new Error('Unexpected title value.');
    }
  }

  function getContentCategoryClassName(contentCat: string) {
    switch (contentCat) {
      case 'exhibition':
        return styles.iconExhibition;
      case 'talk':
        return styles.iconTalk;
      case 'event':
        return styles.iconEvent;
      case 'magazine':
        return styles.iconMagazine;
      case 'book':
        return styles.iconBook;
      case 'news':
        return styles.iconNews;
      default:
        throw new Error('대박사건!');
    }
  }

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen ? null : (
        <main className={styles.main}>
          <h2 className={styles.categoryH2}>{convertTitleToHeader()}</h2>
          <div className={styles.contentGrid}>
            {data.allSanityContent.nodes.sort((a: any, b: any) => {
              const dateA = a.programDate || a.openDate || a.publicationDate ? a.programDate || a.openDate || a.publicationDate : '0';
              const dateB = b.programDate || b.openDate || b.publicationDate ? b.programDate || b.openDate || b.publicationDate : '0';
              if (dateA > dateB) {
                return -1;
              } if (dateA < dateB) {
                return 1;
              } return 0;
            }).map((node: any) => (
              <Link className={styles.iconLink} to={`/${node.slug.current}`}>
                <GatsbyImage
                  className={getContentCategoryClassName(node.contentCategory)}
                  image={node.mainImage.asset.gatsbyImageData}
                  alt={node.title}
                />
              </Link>
            ))}
          </div>
        </main>
      )}
      {menuOpen ? null : <Footer />}
      <MenuModal menuOpen={menuOpen} />
    </>
  );
}
export const query = graphql`
  query categoryPageQuery($title: String) {
    allSanityContent (sort: {fields: _createdAt, order: DESC}, filter: {contentCategory: {eq: $title}}){
      nodes {
        id
        title
        contentCategory
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        programDate(formatString: "YYYY년 MM월 DD일, kk:mm")
        openDate(formatString: "YYYY년 MM월 DD일, kk:mm")
        publicationDate(formatString: "YYYY년 MM월 DD일, kk:mm")
      }
    }
  }`;
